:root {
  /* Background Colors */
  --background-default-gradient: linear-gradient(to right, #373b44, #4286f4);
  --background-radial-gradient: radial-gradient(circle, #2a3a53 0%, #4286f4 35%, #000000 100%);
  --profile-background-gradient: radial-gradient(circle, #ebecf0 0%, #f4f4f4 35%, #fff 100%);

  /* Text Colors */
  --text-main: #2c2929;
  --text-second: rgba(94, 95, 101, 0.824);
  --text-gray: #5e6c84;
  --text-light: #d1e8ff;
  --text-accent: #429dff;

  /* Accent Colors */
  --accent-focus: #0b6bcb;
  --accent-main: #2c2c2c;
  --accent-secondary: #003162;
  --accent-dark: #001325;
  --accent-light: #0b6bcb3d;

  --error-color: #ae1313;
  --error-color-light: #ae13133c;
  --error-color-hover: #ae131383;

  /* Board Colors */
  --board-gray: #bdbdbd;
  --board-normal-gray: #e2e2e2;

  --board-base-gray: #ebecf0;
  --board-dark-gray: #636363;
  --board-light-gray: #f4f4f4;
  --board-white: #ffffff;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  color: var(--text-main);
  background-color: #ffffff;
}

:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

input,
a,
button,
textarea {
  font-family: inherit;
  text-transform: none;
  outline: none;
  border: none;
  color: var(--text-main);
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  border: 0;
  padding: 0;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  font-style: normal;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  color: var(--text-main);
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.lds-roller {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 40px auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--accent-focus);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  gap: 10px;
  color: #F1F1F1;
}

.pagination .active {
  background: linear-gradient(90deg, rgba(104, 79, 221, 1), rgba(47, 146, 172, 1)); 
  color: #f1f1f1;  
  border-radius: 8px; 
  padding: 2px 11px; 
}
.page-number{
  color: #F1F1F1;
}