.course {
  position: relative;
}

.course:after {
  content: attr(data-time);
  bottom: -11px;
  left: 0;
  right: 0;
  font-size: 9px;
  text-align: center;
  width: auto;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .course:after {
    position: absolute;
  }
}
