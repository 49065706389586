.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
}

.block {
  width: 260px;
}


.input {
  --Input-radius: var(--joy-radius-sm);
  --Input-gap: 0.5rem;
  --Input-placeholderColor: inherit;
  --Input-placeholderOpacity: 0.64;
  --Input-decoratorColor: var(--joy-palette-text-icon);
  --Input-focused: 0;
  --Input-focusedThickness: var(--joy-focus-thickness);
  --Input-focusedHighlight: var(--joy-palette-primary-500);
  --Input-minHeight: 2.25rem;
  --Input-paddingInline: 0.75rem;
  --Input-decoratorChildHeight: min(1.75rem, var(--Input-minHeight));
  --Icon-fontSize: var(--joy-fontSize-xl2);
  --Input-decoratorChildOffset: min(
    calc(
      var(--Input-paddingInline) -
        (
          var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) -
            var(--Input-decoratorChildHeight)
        ) / 2
    ),
    var(--Input-paddingInline)
  );
  --_Input-paddingBlock: max(
    (
        var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) -
          var(--Input-decoratorChildHeight)
      ) / 2,
    0px
  );
  --Input-decoratorChildRadius: max(
    var(--Input-radius) - var(--variant-borderWidth, 0px) - var(--_Input-paddingBlock),
    min(var(--_Input-paddingBlock) + var(--variant-borderWidth, 0px), var(--Input-radius) / 2)
  );
  --Button-minHeight: var(--Input-decoratorChildHeight);
  --IconButton-size: var(--Input-decoratorChildHeight);
  --Button-radius: var(--Input-decoratorChildRadius);
  --IconButton-radius: var(--Input-decoratorChildRadius);
  box-sizing: border-box;
  box-shadow: var(--joy-shadowRing, 0 0 #000),
    0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08));
  min-width: 100%;
  min-height: var(--Input-minHeight);
  cursor: text;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-inline: var(--Input-paddingInline);
  border-radius: var(--Input-radius);
  font-family: var(
    --joy-fontFamily-body,
    'Inter',
    var(
      --joy-fontFamily-fallback,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Helvetica,
      Arial,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol'
    )
  );
  font-size: var(--joy-fontSize-md, 1rem);
  line-height: var(--joy-lineHeight-md, 1.5);
  color: var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-700, #32383e));
  --variant-borderWidth: 1px;
  border: var(--variant-borderWidth) solid;
  border-color: var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #cdd7e1));
  background-color: var(--joy-palette-background-surface);
  margin-bottom: 16px;
  --Input-minHeight: 56px;
  --Input-radius: 6px;
}

.inputFile {
  display: none;
}

.customFileUpload {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #32383e;
  border-radius: 4px;
  background: #0b0d0e;
  color: #F1F1F1;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.customFileUpload:hover {
  background-color: #444;
  border-color: #888;
}


