.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  min-width: 320px;

  min-height: calc(100vh - 60px - 72px);
}

@media screen and (min-width: 481) {
  .container {
    padding: 0 32px;
    /* max-width: 767px; */
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0;
    max-width: 1800px;
  }
}
